<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>商家管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <div class="search-box">
<!--                <span class="title">地区：</span>-->
<!--                <el-input size="small" placeholder="请输入地区" v-model="param.area" @keyup.enter.native="getList()">-->
<!--                    <i slot="suffix" class="el-icon-search search-bottom" @click="getList()"/>-->
<!--                </el-input>-->
                <span class="title">名称：</span>
                <el-input size="small" placeholder="请输入商家名称" v-model="param.name" @keyup.enter.native="searchList()">
                    <i slot="suffix" class="el-icon-search search-bottom" @click="searchList()"/>
                </el-input>
                <el-button type="primary" size="small" @click="showAdd()" style="margin-left: 10px;">添加商家</el-button>
            </div>
            <!--------------------用户表格--------------------->
            <el-table :data="list" border v-loading="loading" element-loading-text="加载中,请稍候" :header-cell-style="{background:'#FAFAFA'}"><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60px">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 + query.number * (query.page - 1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fullname" label="商家名称" min-width="100"></el-table-column>
                <el-table-column prop="manager" label="负责人" min-width="80"></el-table-column>
                <el-table-column prop="cell_phone" label="联系方式" min-width="116"></el-table-column>
                <el-table-column prop="address" label="门店地址" min-width="120">
                    <template slot-scope="scope">
                        <span class="textOver" :title="scope.row.address"> {{ scope.row.address }} </span>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="open_time" label="营业时间" width="102"></el-table-column>-->
<!--                <el-table-column prop="features" label="门店特色" width="100">-->
<!--                    <template slot-scope="scope">-->
<!--                        <span class="textOver" :title="scope.row.features"> {{ scope.row.features }} </span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="license" label="营业执照" width="136">
                    <template slot-scope="scope">
                        <el-image style="width: 112px;height: 80px;object-fit: cover;" :src="scope.row.license" :preview-src-list="[scope.row.license]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="cover" label="封面图" width="136">
                    <template slot-scope="scope">
                        <el-image style="width: 112px;height: 80px;object-fit: cover;" :src="scope.row.cover" :preview-src-list="[scope.row.cover]">
                        </el-image>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="remarks" label="门店介绍" width="130">-->
<!--                    <template slot-scope="scope">-->
<!--                        <span class="textOver" :title="scope.row.remarks"> {{ scope.row.remarks }} </span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="status" label="状态" min-width="76">
                    <template slot-scope="scope" >
                        <span v-if="scope.row.status == 0">待审核</span>
                        <span v-if="scope.row.status == 1">通过</span>
                        <span v-if="scope.row.status == 2">不通过</span>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="open_account" label="是否开户" min-width="86">-->
<!--                    <template slot-scope="scope">-->
<!--                        <span v-if="">{{ scope.row.open_account == 1 ? '是' : '否' }}</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="" label="已签约达人" min-width="92">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == 1" type="text" size="mini" @click="showUser(scope.row)">查看</el-button>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="188" fixed="right">
                    <template slot-scope="scope" >

                        <el-button type="text" size="small" @click="showCheck(scope.row)" v-if="scope.row.status == 0">审核</el-button>
                        <el-divider direction="vertical" v-if="scope.row.status == 0"></el-divider>
                        <el-button type="text" size="mini" @click="editItem(scope.row)">编辑</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button type="text" size="small" @click="removeItem(scope.row)">删除</el-button>
<!--                        <el-divider direction="vertical" v-if="scope.row.status == 1"></el-divider>-->
<!--                        <el-button type="text" size="small" v-if="scope.row.status == 1" @click="showBindUser(scope.row)">签约达人</el-button>-->
<!--                        <el-divider direction="vertical"></el-divider>-->
<!--                        <el-button type="text" size="small" @click="showSet(scope.row)">设置开户信息</el-button>-->
<!--                        <el-divider direction="vertical"></el-divider>-->
<!--                        <el-button type="text" size="small" @click="showAccount(scope.row)">开户入驻</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <!--------------------分页组件--------------------->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="query.page" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="query.number"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

            <!--编辑对话框-->
            <el-dialog :title="isEdit ?  '编辑商家' : '添加商家'" :visible.sync="addDialog" width="592px"  append-to-body  @close="closeArenaDialog">
                <div class="dialog-content" style="max-height: 530px;overflow-y: scroll;">
                    <el-form :model="form" :rules="rules" ref="areaRuleForm" label-width="150px">
                        <el-form-item label="商家名称：" prop="fullname">
                            <el-input size="small" v-model="form.fullname" placeholder="请输入" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="负责人：" prop="manager">
                            <el-input size="small" v-model="form.manager" placeholder="请输入真实姓名" maxlength="7" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式：" prop="cell_phone">
                            <el-input size="small" v-model="form.cell_phone" placeholder="请输入"  maxlength="11" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="区域：" prop="area_id">
                            <el-cascader size="small" v-model="form.area_id" :options="areaTree"
                                :props="{emitPath:false, value:'id', label:'name',children:'children', expandTrigger: 'hover' }"
                            ></el-cascader>
                        </el-form-item>
                        <el-form-item label="门店地址：" prop="address">
                            <el-input size="small" v-model="form.address" placeholder="请输入" ></el-input>
                        </el-form-item>
                        <div >
                            <a href="https://lbs.qq.com/getPoint/" target="_blank" style="text-decoration: none;color: #409EFF;margin-left: 150px;">点击去拾取坐标</a>
                        </div>
                        <el-form-item label="门店坐标：" prop="point">
                            <el-input size="small" v-model="form.point" placeholder="拾取坐标复制到此处" @input="changeMessage($event)"></el-input>
                        </el-form-item>
<!--                        <el-form-item label="营业时间：" prop="open_time">-->
<!--                            <el-time-picker v-model="form.open_time" format="HH:mm" value-format="HH:mm" size="small" is-range style="width: 320px"-->
<!--                                range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">-->
<!--                            </el-time-picker>-->
<!--                        </el-form-item>-->
                        <!--<el-form-item label="门店特色：">
                            <el-tag :key="tag" v-for="tag in form.featureArr" closable :disable-transitions="false" @close="handleClose(tag)">
                                {{tag}}
                            </el-tag>
                            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
                                size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showInput">添加特色</el-button>
                        </el-form-item>-->
                        <el-form-item label="营业执照：" prop="license">
                            <div style="display: flex;">
                                <el-upload class="avatar-uploader" action="" :show-file-list="false"
                                           :http-request="uploadLicense" :before-upload="beforeAvatarUpload">
                                    <img v-if="form.license" :src="form.license" class="avatar" alt="">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <div class="tip-box" style="margin-left: 10px;">
                                    <div>图片大小限制10M</div>
                                    <div>仅支持png、jpg、jpeg格式图片</div>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="封面图：" prop="cover">
                            <div style="display: flex;">
                                <el-upload class="avatar-uploader" action="" :show-file-list="false"
                                           :http-request="uploadCover" :before-upload="beforeAvatarUpload">
                                    <img v-if="form.cover" :src="form.cover" class="avatar" alt="">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <div class="tip-box" style="margin-left: 10px;">
                                    <div>图片大小限制10M</div>
                                    <div>仅支持png、jpg、jpeg格式图片</div>
                                </div>
                            </div>
                        </el-form-item>
                        <!--<el-form-item label="商家图：" prop="photoArr">
                            <div class="photo-box">
                                <div class="photo" v-for="(item, index) in form.photoArr" :key="index">
                                    <img v-if="item.type == 0" :src="item.url" alt=""/>
                                    <video v-if="item.type == 1" controlslist="nodownload" disablePictureInPicture muted  x5-video-player-type="h5" x5-video-player-fullscreen
                                           x5-video-orientation="portraint" x-webkit-airplay playsinline x5-playsinline controls webkit-playsinline="true"
                                           ref="videoRef">
                                        <source type="video/mp4" :src="item.url">
                                    </video>
                                    <div class="remove-box">
                                        <i class="el-icon-delete" @click="removePhoto(index)"></i>
                                    </div>
                                </div>
                                <div class="upload-input" v-if="Number(form.photoArr.length) < 20">
                                    <i class="el-icon-plus avatar-uploader-icon" style="position: absolute;"></i>
                                    <span class="tip">图片/视频最多20张</span>
                                    <input type="file" placeholder="点击上传" class="file-input" @change="uploadFile($event)">
                                </div>
                            </div>
                        </el-form-item>-->
                        <!--<el-form-item label="门店介绍：">
                            <el-input type="textarea" placeholder="请输入" v-model="form.remarks" maxlength="200" show-word-limit style="width: 320px;"
                                      :autosize="{ minRows: 4, maxRows: 4}">
                            </el-input>
                        </el-form-item>-->
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="addDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitForm('areaRuleForm')" :loading="btnLoading">保 存</el-button>
                </span>
            </el-dialog>
            <!--审核对话框-->
            <el-dialog :title="'审核商家-' + currentObj.fullname" :visible.sync="checkDialog" width="592px"  append-to-body  @close="closeCheckDialog">
                <div class="dialog-content" >
                    <el-form :model="checkForm" :rules="checkRules" ref="checkRuleForm" label-width="150px">
                        <el-form-item label="审核：" prop="status">
                            <el-radio v-model="checkForm.status" :label="1">通过</el-radio>
                            <el-radio v-model="checkForm.status" :label="2">不通过</el-radio>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="checkDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitCheck('checkRuleForm')" :loading="btnLoading">保 存</el-button>
                </span>
            </el-dialog>
            <!--已签约达人对话框-->
            <el-dialog :title="currentObj.fullname+'-已签约达人'" :visible.sync="userDialog" width="1300px"  append-to-body  @close="closeUserDialog">
                <div style="height: 490px;overflow-y: auto;">
                    <el-table :data="userList" border :header-cell-style="{background:'#FAFAFA'}">
                        <el-table-column type="index" label="序号" width="60px">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fullname" label="姓名"></el-table-column>
                        <el-table-column prop="nickname" label="昵称"></el-table-column>
                        <el-table-column prop="" label="性别" width="60">
                            <template slot-scope="scope">
                                <span>{{ scope.row.sex == 0 ? '女' : '男' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cell_phone" label="手机号"  width="118"></el-table-column>
                        <el-table-column prop="birthday" label="出生日期">
                            <template slot-scope="scope">
                                <span>{{  scope.row.birthday.substring(0, 10) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="area_id" label="区域位置">
                            <template slot-scope="scope">
                                <span>{{ showArea(scope.row.area_id) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="items" label="技能"  width="132">
                            <template slot-scope="scope">
                                <span class="textOver">{{ showItem(scope.row.items) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="height" label="身高"  width="70">
                            <template slot-scope="scope">
                                <span>{{  scope.row.height + 'cm' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="weight" label="体重"  width="60">
                            <template slot-scope="scope">
                                <span>{{  scope.row.weight + 'kg' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="star_sign" label="星座"  width="70"></el-table-column>
                        <el-table-column prop="avatar" label="头像" width="131">
                            <template slot-scope="scope">
                                <div class="photo">
                                    <el-image  :src="scope.row.avatar" style="width: 112px;height: 80px;object-fit: cover;" :preview-src-list="[scope.row.avatar]">
                                    </el-image>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="操作" width="60" >
                            <template slot-scope="scope" >
                                <el-button type="text" size="small" @click="removeTeach(scope.row)">解约</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="userDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="userDialog = false">关 闭</el-button>
                </span>
            </el-dialog>

            <!--未达人对话框-->
            <el-dialog :title="currentObj.fullname+'-签约达人'" :visible.sync="bingUserDialog" width="1300px"  append-to-body  @close="closeBindUserDialog">
                <div style="height: 490px;">
                    <el-button type="primary" size="small" @click="addBindUser()" :loading="btnLoading">签约达人</el-button>
                    <el-table :data="bindUserList" border :header-cell-style="{background:'#FAFAFA'}" @selection-change="handleSelectionChange"  height="465">
                        <el-table-column
                            type="selection"
                            width="55">
                        </el-table-column>
                        <el-table-column type="index" label="序号" width="60px">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fullname" label="姓名"></el-table-column>
                        <el-table-column prop="nickname" label="昵称"></el-table-column>
                        <el-table-column prop="" label="性别" width="60">
                            <template slot-scope="scope">
                                <span>{{ scope.row.sex == 0 ? '女' : '男' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cell_phone" label="手机号"  width="118"></el-table-column>
                        <el-table-column prop="birthday" label="出生日期">
                            <template slot-scope="scope">
                                <span>{{  scope.row.birthday.substring(0, 10) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="area_id" label="区域位置">
                            <template slot-scope="scope">
                                <span>{{ showArea(scope.row.area_id) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="height" label="身高"  width="70">
                            <template slot-scope="scope">
                                <span>{{  scope.row.height + 'cm' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="weight" label="体重"  width="60">
                            <template slot-scope="scope">
                                <span>{{  scope.row.weight + 'kg' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="star_sign" label="星座"  width="70"></el-table-column>
                        <el-table-column prop="avatar" label="头像" width="131">
                            <template slot-scope="scope">
                                <div class="photo">
                                    <el-image  :src="scope.row.avatar" style="width: 112px;height: 80px;object-fit: cover;" :preview-src-list="[scope.row.avatar]">
                                    </el-image>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="bingUserDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="bingUserDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
            <!--开户信息对话框-->
            <el-dialog :title="'开户设置-'+setForm.fullname" :visible.sync="setDialog" width="592px"  append-to-body  @close="closeSetDialog">
                <div class="dialog-content" style="max-height: 530px;overflow-y: auto;">
                    <el-form :model="setForm" :rules="setRules" ref="setRuleForm" label-width="150px">
                        <el-form-item label="身份证号：" prop="cert_no">
                            <el-input size="small" v-model="setForm.cert_no" placeholder="请输入" maxlength="18" show-word-limit ></el-input>
                        </el-form-item>
                        <el-form-item label="身份证有效期类型：" prop="cert_validity_type">
                            <el-radio-group v-model="setForm.cert_validity_type"  @change="changeType">
                                <el-radio :label="0">非长期有效</el-radio>
                                <el-radio :label="1">长期有效</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="身份证有效期：" prop="time" v-if="setForm.cert_validity_type == 0">
                            <el-date-picker size="small" v-model="setForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" @input="changeDate">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="开户行区域编码：" prop="code">
                            <el-cascader size="small" v-model="setForm.code" :options="areaArr"
                                         :props="{value:'id', label:'name',children:'children', expandTrigger: 'hover' }"
                                         @change="changeCode"></el-cascader><br/>

                            <div style="line-height: 20px;font-size: 13px;">{{ showCode }}</div>
                        </el-form-item>
                        <el-form-item label="银行卡号：" prop="bank_card_no">
                            <el-input size="small" v-model="setForm.bank_card_no" placeholder="请输入" ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="setDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitSetForm('setRuleForm')" :loading="btnLoading">保 存</el-button>
                </span>
            </el-dialog>
            <!--开户入驻对话框-->
            <el-dialog :title="'开户入驻-'+currentObj.fullname" :visible.sync="accountDialog" width="592px"  append-to-body  @close="closeAccountDialog">
                <div class="dialog-content" style="max-height: 530px;overflow-y: auto;">
                    <el-form  label-width="150px">
                        <el-form-item label="姓名：">
                            {{ currentObj.manager }}
                        </el-form-item>
                        <el-form-item label="身份证号：">
                            {{ currentObj.cert_no }}
                        </el-form-item>
                        <el-form-item label="身份证有效期类型：">
                            <span v-if="currentObj.cert_validity_type == 0">非长期有效</span>
                            <span v-if="currentObj.cert_validity_type == 1">长期有效</span>
                        </el-form-item>
                        <el-form-item label="身份证有效期：" v-if="currentObj.cert_validity_type == 0">
                            {{ currentObj.cert_begin_date ? (currentObj.start + '至' + currentObj.end) : '' }}
                        </el-form-item>
                        <el-form-item label="开卡银行编码：">
                            {{currentObj.bank_prince_code ? (currentObj.bank_prince_code + '/' + currentObj.bank_city_code) : '' }}
                        </el-form-item>
                        <el-form-item label="银行卡号：" >
                            {{ currentObj.bank_card_no }}
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="accountDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitAccountForm()" :loading="btnLoading">开户入驻</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
import _ from "lodash";
export default {
    name: "list",

    data() {
        //校验价格
        var checkPrice = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入'))
            } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
                callback(new Error('金额格式错误'))
            } else {
                callback()
            }
        }
        //校验手机号
        var validatorPhone = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入'))
            } else if (!/^1[3-9]\d{9}$/.test(value)) {
                callback(new Error('手机号格式错误'))
            } else {
                callback()
            }
        }
        return {
            param: {//请求参数
                area: '',
                name: ''//名称
            },
            list: [],//表格列表
            total: 0,//总条数
            query: {
                page: 1,//页码
                number: 10,//数量
            },
            loading: false,//表格加载loading
            isEdit: false,//是否编辑
            addDialog: false,//添加弹窗
            inputVisible: false,//门店特色输入框显示
            inputValue: '',//门店特色
            form: {//表单提交内容
                fullname: '',
                manager: '',
                cell_phone: '',
                area_id: '',
                address: '',
                point: '',
               // open_time: '',
                //featureArr: [],
                license: '',
                cover: '',
                //remarks: '',
                //photoArr: [],
            },
            rules: {// 表单校验规则对象
                fullname: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                manager: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                cell_phone: [
                    {required:true,validator: validatorPhone,trigger:'blur'},
                ],
                area_id: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                address: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                point: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                // open_time: [
                //     {required: true, message: '请选择', trigger: 'change'},
                // ],
                license: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                cover: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                // photoArr: [
                //     {required: true, message: '请选择', trigger: 'change'},
                // ],
            },
            btnLoading: false,//按钮状态
            //审核
            currentObj: {},//当前对象
            checkDialog: false,//审核弹窗
            checkForm: {
                status: 1,//状态 0:待审核;1:通过;2-不通过;
            },
            checkRules: {// 表单校验规则对象
                status: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
            },
            areaTree: [],//区域树
            userDialog: false,//查看签约达人
            userList: [],//已签约达人列表
            methodArr: [],//打法
            levelArr: [],//级别

            bingUserDialog: false,//签约达人弹窗
            bindUserList: [],//可签约达人列表
            selectedData: [],//勾选的达人
            //开户信息
            setDialog: false,
            setForm: {//表单提交内容
                cert_no: '',
                cert_validity_type: 0,
                time: '',
                 code: [],
               // area_id: '',
                bank_card_no: '',
            },
            showCode: '',//编码
            setRules: {// 表单校验规则对象
                cert_no: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                cert_validity_type: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                time: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                code: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                bank_card_no: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
            },
            areaArr: [],////区域树(开户行区域编码选项)
            accountDialog: false,//开户入驻弹窗
        }
    },
    mounted () {
        this.getAreaTree()//查区域
        this.getList()//查列表
        this.getPlayItem();  //查技能
        this.getAreaCode();//查省市区域
    },
    methods: {
        //查技能
        async getPlayItem() {
            let data = {
                type: 'play_item',
                pageType: 0,//0不分页 1分页
            }
            let res = await this.$http.get('/admin/api/get/dict', { params: data})
            if (res.data.code == 200) {
                if(res.data.data) {
                    this.itemArr = res.data.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //技能显示
        showItem(item) {
            let str = '';
            if(item) {
                for(let i=0; i<item.length; i++) {
                    str += item[i].item + '，';
                }
                return str.slice(0, str.length-1);
            }
        },
        // 强制更新文本框的值
        changeMessage() {
            this.$forceUpdate()
        },
        //打开添加弹窗
        showAdd() {
            this.addDialog = true
            this.form.point = ''
            this.$forceUpdate()
        },
        //查开户行区域编码选项
        async getAreaCode() {
            let { data: res } = await this.$http.get('/admin/api/area/treeList?level=2', )
            if (res.code == 200) {
                if(res.data) {
                    this.areaArr = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //切换身份证有效期类型
        changeType() {
            if(this.setForm.cert_validity_type == 1) {
                this.setForm.time = []
            }
            this.$forceUpdate()
        },
        //表格勾选
        handleSelectionChange(val) {
            this.selectedData = val;
        },
        //提交签约达人
        async addBindUser() {
            if(this.selectedData.length == 0) {
                return this.$message.error('请先勾选要签约的达人')
            }
            let title = '';
            if(this.selectedData.length == 1) {
                title = '确认要签约”'+ this.selectedData[0].fullname +'“这位达人吗？';
            }else {
                title = '确认要签约这' + this.selectedData.length + '位达人吗？';
            }
            this.$confirm('', title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let ids = [];
                        for(let i=0; i<this.selectedData.length; i++) {
                            ids.push(this.selectedData[i].id)
                        }
                        let data = {
                            id: this.currentObj.id,
                            assistant_ids: ids,
                        }
                        let res = await this.$http.post('/admin/api/arena/addAssistant', data)
                        if (res.data.code === 200) {
                            done();
                            this.$message.success('操作成功！')
                            this.closeBindUserDialog()
                            await this.searchList();
                        } else {
                            this.$message.error(res.data.message)
                        }
                        instance.confirmButtonLoading = false;

                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
        //显示区域名称
        showArea(areaId) {
            let str = '';
            let obj = this.fundNode(this.areaTree, areaId);
            if(obj) {
                str = obj.name;
            }
            return str;
        },
        //按id递归查出对象
        fundNode(tree, id) {
            var stark = []
            stark = stark.concat(tree)
            while (stark.length) {
                var temp = stark.shift()
                if (temp.children) {
                    stark = stark.concat(temp.children)
                }
                if (temp.id === id) {
                    return temp
                }
            }
        },
        //查看已签约达人
        async showUser(rows) {
            this.userDialog = true
            this.currentObj = rows
            this.getBindUserList(rows.id);
        },
        //查已签约达人
        async getBindUserList(arenaId) {
            let { data: res } = await this.$http.get('/admin/api/assistant/getByArena', { params: {arena_id: arenaId}})
            if (res.code == 200) {
                if(res.data) {
                    this.userList = res.data;
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //关闭已签约达人
        closeUserDialog() {
            this.userDialog = false
            this.userList = []
        },
        //查看可签约达人
        async showBindUser(rows) {
            this.bingUserDialog = true
            this.currentObj = rows
            let { data: res } = await this.$http.get('/admin/api/assistant/noArenaList', )
            if (res.code == 200) {
                if(res.data) {
                    this.bindUserList = res.data;
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //关闭可签约达人
        closeBindUserDialog() {
            this.bingUserDialog = false
            this.bindUserList = []
        },
        //查区域
        async getAreaTree() {
            let { data: res } = await this.$http.get('/admin/api/area/treeList', )
            if (res.code == 200) {
                if(res.data) {
                    this.areaTree = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //从第一页开始重新加载列表
        searchList() {
            this.query.page = 1
            this.query.number = 10
            this.getList();
        },
        //列表
        async getList() {
            this.loading = true;
            let data = {
                fullname: this.param.name,
                //area: this.param.area,
                page: this.query.page,
                number: this.query.number,
            }
            let { data: res } = await this.$http.get('/admin/api/arena/pageList', { params: data})
            this.loading = false;
            if (res.code == 200) {
                if(res.data.result) {
                    this.list = res.data.result
                    this.total = res.data.total
                }
            } else {
                this.$message.error(res.message)
            }
        },
        // 监听每页显示多少条的改变
        handleSizeChange (newSize) {
            this.query.page = 1
            this.query.number = newSize
            this.getList()
        },
        // 监听选择第了几页
        handleCurrentChange (newPage) {
            this.query.page = newPage
            this.getList()
        },
        //删除门店特色
        // handleClose(tag) {
        //     this.form.featureArr.splice(this.form.featureArr.indexOf(tag), 1);
        //     this.$forceUpdate()
        // },
        //添加门店特色
        // showInput() {
        //     this.inputVisible = true;
        //     this.$nextTick(_ => {
        //         this.$refs.saveTagInput.$refs.input.focus();
        //     });
        // },
        //确定门店特色
        // handleInputConfirm() {
        //     let inputValue = this.inputValue;
        //     if (inputValue) {
        //         this.form.featureArr.push(inputValue);
        //     }
        //     this.inputVisible = false;
        //     this.inputValue = '';
        // },
        //添加/编辑
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.btnLoading = true;
                    // let arr = [];
                    // for(let i=0; i<this.form.photoArr.length; i++) {
                    //     arr.push(this.form.photoArr[i].url);
                    // }
                    let param = {
                        fullname: this.form.fullname.trim(),
                        type: 0,
                        manager: this.form.manager.trim(),
                        cell_phone: this.form.cell_phone.trim(),
                        address: this.form.address.trim(),
                        longitude: this.form.point.trim().split(",")[1],
                        latitude: this.form.point.trim().split(",")[0],
                        //open_time: this.form.open_time.join('-'),
                        //features: this.form.featureArr.join('，'),
                        license: this.form.license,
                        cover: this.form.cover,
                        //photos: arr,
                        //remarks: this.form.remarks,
                        area_id: this.form.area_id
                    }
                    let res = {};
                    if (this.isEdit) {
                        param.id = this.form.id;
                        res = await this.$http.post('/admin/api/arena/update', param)
                    } else {
                        res = await this.$http.post('/admin/api/arena/add', param)
                    }
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        this.closeArenaDialog();
                        await this.searchList();
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭对话框
        closeArenaDialog() {
            if (this.form.id) {
                delete this.form.id;
            }
            this.btnLoading = false;
            this.$refs['areaRuleForm'].resetFields();
            this.$forceUpdate();
            //this.form.photoArr = [];
            this.addDialog = false;
            this.isEdit = false;
           // this.form.remarks = ''
            //this.form.featureArr = []

        },
        //编辑
        async editItem(rows) {
            this.isEdit = true;
            this.$nextTick(async () => {
                this.form = _.cloneDeep(rows);
                //this.form.open_time = rows.open_time.split("-")
                if(rows.latitude && rows.longitude) {
                    this.$set(this.form, 'point',  rows.latitude+','+rows.longitude);
                }
                //this.form.photoArr = [];
               // this.form.featureArr = rows.features ? rows.features.split("，") : [];
                this.$forceUpdate();
                //this.getPhotos(rows);
            })
            this.$forceUpdate();
            this.addDialog = true; //打开对话框
        },
        //查商家图
        async getPhotos(row) {
            let res = await this.$http.get('/admin/api/arena/detail', { params: {id: row.id}})
            if (res.data.code == 200) {
                if(res.data.data) {
                    let list = res.data.data.photos;
                    let newArr = []
                    for(let i=0; i<list.length; i++) {
                        let type = 0;//0图片1视频
                        if(list[i].photo_url.indexOf('mp4') != -1) {
                            type = 1;
                        }
                        newArr.push({type: type, url: list[i].photo_url});
                    }
                    this.form.photoArr = newArr;
                    this.$forceUpdate()
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //打开审核弹窗
        showCheck(row) {
            this.currentObj = row
            this.checkDialog = true
        },
        //审核
        submitCheck(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.btnLoading = true;
                    let param = {
                        id: this.currentObj.id,
                        status: this.checkForm.status,
                    }
                    let res = await this.$http.post('/admin/api/arena/verify', param)
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        this.closeCheckDialog();
                        await this.searchList();
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭审核弹窗
        closeCheckDialog() {
            if (this.checkForm.id) {
                delete this.checkForm.id;
            }
            this.btnLoading = false;
            this.$refs['checkRuleForm'].resetFields();
            this.checkDialog = false;
        },
        //删除
        removeItem(rows) {
           // this.$confirm('此操作将永久删除', '确认要删除“' + rows.name + '”吗？', {
            this.$confirm('此操作将永久删除', '确认要删除吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let res = await this.$http.post('/admin/api/arena/delete', {id: rows.id})
                        if (res.data.code === 200) {
                            done();
                            this.$message.success('操作成功！')
                            await this.searchList();
                        } else {
                            this.$message.error(res.data.message)
                        }
                        instance.confirmButtonLoading = false;
                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
        //上传营业执照
        uploadLicense(http) {
            this.uploadImage(http, 0);
        },
        //上传封面图
        uploadCover(http) {
            this.uploadImage(http, 1);
        },
        //type 0营业执照 1封面图
        async uploadImage(http, type) {
            let file = http.file;
            let formData = new FormData();
            formData.append('file', file)
            let {data: res} = await this.$http({
                url: '/admin/api/upload',
                data: formData,
                headers: {
                    'Content-Type': "multipart/form-data; charset=UTF-8",
                },
                method:'post'
            });
            if (res.code === 200) {
                if(type == 0) {
                    this.form.license = res.data.url;
                } else if(type == 1) {
                    this.form.cover = res.data.url;
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //上传前校验文件类型
        beforeAvatarUpload(file) {
            let type = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (type === 'png' || type === 'jpg' || type === 'jpeg') {
                if(file.size > 1024 * 1000 * 10) {
                    this.$message.error('图片不允许超过10M');
                    return false;
                }
            } else {
                this.$message.error('仅支持png、jpg、jpeg格式图片！');
                return false;
            }
        },
        //选中就上传
        async uploadFile(event) {
            let fileType = 0;
            let file = event.target.files[0]
            // 判断文件类型   如果是jpg、png 则支持上传。否则 中断并提示
            let type = file.type;
            if (file.size > 1024 * 1024 * 50) {
                this.$message.error('文件大小不能超过50M')
                event.target.value = '';
                return;
            }
            if(type.indexOf('video') !== -1){
                fileType = 1;
                if (file.type !== 'video/mp4') {
                    this.$message.error('视频仅支持mp4格式')
                    event.target.value = '';
                    return
                }
                event.target.value = ''
            } else if(type.indexOf('image') !== -1) {
                if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
                    this.$message.error('图片仅支持png/jpg/jpeg格式')
                    event.target.value = '';
                    return
                }
                event.target.value = '';
            } else {
                return this.$message.error('文件格式不正确')
            }
            let formData = new FormData();
            formData.append('file', file)
            let {data: res} = await this.$http({
                url: '/admin/api/upload',
                data: formData,
                headers: {
                    'Content-Type': "multipart/form-data; charset=UTF-8",
                },
                method:'post'
            });
            if (res.code === 200) {
                this.form.photoArr.push({type: fileType, url: res.data.url})
                this.$forceUpdate()
            } else {
                this.$message.error(res.message)
            }
        },
        //删除商家图列表
        removePhoto(index) {
            this.form.photoArr.splice(index, 1);
            this.$forceUpdate()
        },
        //解约达人
        removeTeach(rows) {
            this.$confirm('', '确认要解约“' + rows.fullname + '”这位达人吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let res = await this.$http.post('/admin/api/arena/removeAssistant', {id: rows.arena_id, assistant_id: rows.id})
                        if (res.data.code === 200) {
                            done();
                            this.$message.success('操作成功！')
                            await this.getBindUserList(rows.arena_id);
                        } else {
                            this.$message.error(res.data.message)
                        }
                        instance.confirmButtonLoading = false;
                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
        //身份证有效期输入更新页面显示
        changeDate() {
            this.$forceUpdate()
        },
        //切换编码
        changeCode() {
            let str = '';
            if(this.setForm.code) {
                str = this.setForm.code.join(' / ');
            }
            this.showCode = str;
        },
        //打开设置开户信息
        showSet(rows) {
            this.$nextTick(() => {
                this.setForm = _.cloneDeep(rows);
                if(rows.bank_prince_code && rows.bank_city_code) {
                   // this.setForm.code = [rows.bank_prince_code, rows.bank_city_code]
                    this.$set(this.setForm, 'code', [rows.bank_prince_code, rows.bank_city_code]);
                    this.changeCode();
                } else {
                  //  this.setForm.code = []
                    this.$set(this.setForm, 'code', [rows.bank_prince_code, rows.bank_city_code]);
                }
                if(rows.cert_validity_type == 0) {
                    if(rows.cert_begin_date && rows.cert_end_date) {
                        let start = rows.cert_begin_date;
                        let star1 = start.slice(0, 4) + '-' + start.slice(4, start.length);
                        let res = star1.slice(0, 7) + '-' + star1.slice(7, star1.length);
                        let end = rows.cert_end_date;
                        let end1 = end.slice(0, 4) + '-' + end.slice(4, end.length);
                        let res1 = end1.slice(0, 7) + '-' + end1.slice(7, end1.length);
                        this.$set(this.setForm, 'time', [res, res1]);
                    } else {
                        this.$set(this.setForm, 'time', '');
                    }
                } else {
                    this.$set(this.setForm, 'time', '');
                }
                this.$forceUpdate();
            })
            this.setDialog = true; //打开对话框
        },
        //添加/编辑
        submitSetForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if(this.setForm.cert_validity_type == 0) {
                        if(!this.setForm.time) {
                            return  this.$message.error('请选择身份证有效期')
                        }
                    }
                    if(!this.setForm.code) {
                        return  this.$message.error('请选择开户行区域编码')
                    }
                    this.btnLoading = true;
                    let param = {
                        id: this.setForm.id,
                        cert_no: this.setForm.cert_no,
                        cert_validity_type: this.setForm.cert_validity_type,
                        cert_begin_date: this.setForm.cert_validity_type == 0 ? this.setForm.time[0].split("-").join('') : '',
                        cert_end_date: this.setForm.cert_validity_type == 0 ? this.setForm.time[1].split("-").join('') : '',
                        bank_prince_code: this.setForm.code[0],
                        bank_city_code: this.setForm.code[1],
                        bank_card_no: this.setForm.bank_card_no,
                    }
                    let res = await this.$http.post('/admin/api/arena/setBankInfo', param)
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        this.closeSetDialog();
                        await this.searchList();
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭对话框
        closeSetDialog() {
            this.btnLoading = false;
            this.$refs['setRuleForm'].resetFields();
            this.$set(this.setForm, 'time', '');
            this.setDialog = false;
            this.showCode = '';
        },
        //打开设置开户信息
        showAccount(rows) {
            this.$nextTick(() => {
                this.currentObj = rows
                if(rows.cert_begin_date && rows.cert_end_date) {
                    let start = rows.cert_begin_date;
                    let star1 = start.slice(0, 4) + '-' + start.slice(4, start.length);
                    let res = star1.slice(0, 7) + '-' + star1.slice(7, star1.length);
                    let end = rows.cert_end_date;
                    let end1 = end.slice(0, 4) + '-' + end.slice(4, end.length);
                    let res1 = end1.slice(0, 7) + '-' + end1.slice(7, end1.length);
                    this.currentObj.start = res;
                    this.currentObj.end = res1;
                }
            })
            this.accountDialog = true; //打开对话框
        },
        //添加/编辑
        async submitAccountForm() {
            if(!this.currentObj.bank_card_no) {
                return  this.$message.error('请先设置开户信息')
            }
            this.btnLoading = true;
            let res = await this.$http.post('/admin/api/arena/openAccount', { id: this.currentObj.id})
            if (res.data.code === 200) {
                this.btnLoading = false;
                this.$message.success('操作成功！')
                this.closeAccountDialog();
                await this.searchList();
            } else {
                this.btnLoading = false;
                this.$message.error(res.data.message)
            }
        },
        //关闭对话框
        closeAccountDialog() {
            this.btnLoading = false;
            this.currentObj = {}
            this.$forceUpdate();
            this.accountDialog = false;
        },
    }
}
</script>

<style scoped>
.avatar-uploader .el-upload {border: 1px dashed #d9d9d9;border-radius: 6px;cursor: pointer;position: relative;overflow: hidden;}
.avatar-uploader .el-upload:hover {border-color: #409EFF;}
.avatar-uploader-icon {border: 1px dashed #c0ccda;font-size: 28px;color: #8c939d;width: 110px;height: 78px;line-height: 80px;text-align: center;background-color: #fbfdff;
position: relative;}
.avatar-uploader-icon:hover {border: 1px dashed #409EFF;}
.avatar {width: 110px;height: 78px;display: block;object-fit: cover;}
.tip-box {margin-left: 10px;}
.tip-box div {height: 25px;}

.photo-box {display: flex;flex-wrap: wrap;}
.photo {width: 112px;height: 80px;margin-right: 6px;margin-bottom: 6px; background-color: #eee;}
.upload-input {width: 112px;height: 80px;margin-right: 6px;margin-bottom: 6px;position: relative;background-color: #fbfdff;}
.file-input {width: 112px;height: 80px;position: absolute;left: 0;top: 0;opacity: 0;}
.photo img{width: 100%;height: 100%;object-fit: cover;}
.photo video {width: 100%;height: 100%;object-fit: cover;}
.upload-input .tip {top: 44px;font-size: 12px;left: 5px;position: absolute;}
.photo:hover .remove-box {display: block;}
.remove-box {position: absolute;width: 112px;height: 80px;background-color: rgba(0, 0, 0, .5);color: #fff;top: 0;cursor: pointer;display: none;}
.remove-box .el-icon-delete {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);font-size: 20px;}
.el-tag + .el-tag {margin-left: 10px;}
.button-new-tag {margin-left: 10px;height: 32px;line-height: 30px;padding-top: 0;padding-bottom: 0;}
.input-new-tag {width: 90px;margin-left: 10px;vertical-align: bottom;}
.textOver { display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
</style>
<style>
.photo .el-image .el-image__inner {width: 100%;height: 100%;object-fit: cover;}
.el-image-viewer__wrapper {z-index: 3000 !important;}
.el-image img {object-fit: cover;}
</style>
